// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { CaptureConsole } from '@sentry/integrations'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENV =
  process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT
const COMMIT_SHA = process.env.COMMIT_SHA || process.env.NEXT_PUBLIC_COMMIT_SHA

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://326c8ab397aa4a4f909d822686316296@o954261.ingest.sentry.io/5903273',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  integrations: [
    new CaptureConsole({
      levels: ['error']
    })
  ],
  environment: SENTRY_ENV,
  release: COMMIT_SHA,
  beforeSend: (event, hint) => {
    if (SENTRY_ENV === 'local') {
      console.error(event)
      return null
    }
    
    // Check if the event contains the specific error
    const errorMessage = hint?.originalException?.message || event?.message;
    const ignoredErrors = [
      "Abort fetching component for route",
      "attempted to hard navigate",
      "Failed to lookup"
    ]
    if(ignoredErrors.some((error) => errorMessage?.includes(error))) {
      return null
    }

    return event
  }
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
