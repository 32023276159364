import {
  ListingsApi,
  Configuration,
  ReviewsApi,
  LocationsApi,
  InboxApi,
  RetreatsApi
} from 'typescript-axios'
import axios from 'axios'
import haversine from 'haversine-distance'
import useSWR from 'swr'
import { isBrowser } from 'common'

export const API_URL = process.env.API_HOST
const BASE_PATH =
  typeof window === 'undefined'
    ? process.env.API_HOST
    : process.env.NEXT_PUBLIC_HOST

export function getAxiosParams(basePath = BASE_PATH) {
  return new Configuration({ basePath })
}

const RETREATS_SERVICE = new RetreatsApi(getAxiosParams())
const LISTINGS_SERVICE = new ListingsApi(getAxiosParams())
const LOCATIONS_SERVICE = new LocationsApi(getAxiosParams())
const REVIEW_SERVICE = new ReviewsApi(getAxiosParams())
const INBOX_SERVICE = new InboxApi(getAxiosParams())

export function callWithoutCache(fun, args) {
  return fun(args, {
    _vercel_no_cache: 1,
    headers: {
      'x-purge-cache': true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    }
  })
}

export function postMessage({
  content,
  ownerId,
  targetId,
  chatId,
  file,
  options
}) {
  return INBOX_SERVICE.createMessageApiV1InboxPost(
    content ?? undefined,
    ownerId ?? undefined,
    targetId ?? undefined,
    chatId ?? undefined,
    file ?? undefined,
    options ?? undefined
  )
}

export async function getListings(params, purgeCache, tiny = false) {
  return await getListingsAsync(params, purgeCache, tiny).then(
    (res) => res.data
  )
}

export async function getRetreats(params, purgeCache, tiny = false) {
  return await getRetreatsAsync(params, purgeCache, tiny).then(
    (res) => res.data
  )
}

export async function getListingsAsync(
  {
    skip,
    isOnline,
    limit,
    activity,
    parentActivity,
    location,
    dateStart,
    dateEnd,
    sortBy,
    owner,
    onlyNotFull,
    onlyNotEmpty,
    firstname,
    priceMin,
    priceMax,
    options
  },
  purgeCache = false,
  tiny = false
) {
  if (purgeCache) {
    options = options ?? {}
    options.headers = options.headers ?? {}
    options.headers = {
      ...options.headers,
      'x-purge-cache': true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    }
    options.params = { ...options.params, _vercel_no_cache: 1 }
  }

  if (tiny) {
    return LISTINGS_SERVICE.readListingsTinyApiV1ListingsTinyGet(
      skip ?? 0,
      limit ?? 200,
      activity ?? undefined,
      parentActivity ?? undefined,
      isOnline !== undefined || isOnline !== null ? isOnline : undefined,
      location || undefined,
      dateStart ? Math.floor(dateStart.unix() / 60) * 60 : undefined,
      dateEnd ? Math.floor(dateEnd.unix() / 60) * 60 : undefined,
      sortBy ?? undefined,
      owner ?? undefined,
      onlyNotFull ?? false,
      onlyNotEmpty ?? false,
      priceMin ?? undefined,
      priceMax ?? undefined,
      firstname ?? undefined,
      options ?? undefined
    )
  }
  return LISTINGS_SERVICE.readListingsApiV1ListingsGet(
    skip ?? 0,
    limit ?? 200,
    activity ?? undefined,
    parentActivity ?? undefined,
    isOnline !== undefined || isOnline !== null ? isOnline : undefined,
    location || undefined,
    dateStart ? Math.floor(dateStart.unix() / 60) * 60 : undefined,
    dateEnd ? Math.floor(dateEnd.unix() / 60) * 60 : undefined,
    sortBy ?? undefined,
    owner ?? undefined,
    onlyNotFull ?? false,
    onlyNotEmpty ?? false,
    priceMin ?? undefined,
    priceMax ?? undefined,
    firstname ?? undefined,
    options ?? undefined
  )
}
export async function getRetreatsAsync(
  {
    skip,
    limit,
    dateStart,
    dateEnd,
    sortBy,
    owner,
    options
  },
  purgeCache = false,
  tiny = false
) {
  if (purgeCache) {
    options = options ?? {}
    options.headers = options.headers ?? {}
    options.headers = {
      ...options.headers,
      'x-purge-cache': true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    }
    options.params = { ...options.params, _vercel_no_cache: 1 }
  }

  return RETREATS_SERVICE.readRetreatsApiV1RetreatsGet(
    owner ?? undefined,
    dateStart ? Math.floor(dateStart.unix() / 60) * 60 : undefined,
    dateEnd ? Math.floor(dateEnd.unix() / 60) * 60 : undefined,
    skip ?? 0,
    limit ?? 100,
    sortBy ?? undefined,
    options ?? undefined
  )
}

export async function getReviews({ skip, limit, revieweeId }) {
  return await REVIEW_SERVICE.readReviewsApiV1ReviewsGet(
    skip,
    limit,
    revieweeId
  ).then((res) => res.data)
}

export async function getClientLatLng() {
  const res = await axios
    .get('https://api.ipregistry.co/?key=bxcpxis0lvx7es')
    .then((res) => res.data)
  return { lat: res.location.latitude, lng: res.location.longitude }
}

export async function getClientLocation() {
  if (!isBrowser()) return
  try {
    const locReq = LOCATIONS_SERVICE.readLocationsApiV1LocationsGet()
    const clientLocaReq = getClientLatLng()
    await axios.all([locReq, clientLocaReq])

    const locs = await locReq.then((res) => res.data)
    const clientLoc = await clientLocaReq
    const clientCoord = { latitude: clientLoc.lat, longitude: clientLoc.lng }
    let bestLoc = locs[0]
    let bestDst = 1000000000000000000.0
    locs.map((loc) => {
      const dst = haversine(clientCoord, loc)
      if (dst < bestDst) {
        bestLoc = loc
        bestDst = dst
      }
    })

    if (bestDst) {
      return bestLoc
    }
    return null
  } catch (e) {
    return null
  }
}

export function useClientLocation() {
  const { data, mutate, error } = useSWR('client_loc', getClientLocation, {
    refreshInterval: 3600 * 12 * 1000,
    dedupingInterval: 3600 * 12 * 1000
  })

  const loading = !data && !error
  const loggedOut = !!error

  return {
    loading,
    clientLocation: data,
    mutate
  }
}
